import React from 'react';
import { connect } from 'react-redux';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { useUpdateCheck } from 'react-update-notification';

import CookieConsent from "react-cookie-consent";

import {
  Button,
} from 'react-bootstrap';

import 'assets/base.scss';
import 'assets/css/feather.css';
import 'assets/css/style.css';
import 'assets/css/responsive.css';

import { loadLang } from 'redux/actions';
import { loadUser } from 'redux/actions/auth';
import { loadShop } from 'redux/actions/shop';
// import { handleSubmitOnKeyDown } from 'core/utils';
import { ShopContext, LangContext } from 'context';

import { Header } from 'components/header';
import { ScrollTop } from 'components/scroll';
import renderRoutes from 'router';
import { Footer } from 'components/footer';

const helmetContext = {};

const ShopMetaTags = ({shop}) => {
  const description = (shop?.get_text_short_desc || '')
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{shop.name}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={shop.name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ shop.get_uri } />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={ description.slice(0, 140) } />
      <meta name="twitter:description" content={ description.slice(0, 200) } />
      <meta name="twitter:text:description" content={ description.slice(0, 200) } />
      <meta name="twitter:site" content="@polder123boy" />
    </Helmet>
  );
};


const NotificationContainer = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 24 * 60 * 60 * 1000, // 1day
  });
  console.log(status)

  if (status === 'checking' || status === 'current') {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute', left: '1rem', bottom: '4rem', zIndex: 9999
      }}
    >
      <Button
        variant="default"
        onClick={reloadPage}
        style={{
          maxWidth: '140px',
          backgroundImage: 'linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%)',
        }}
      >
        Refresh to update the app
      </Button>
    </div>
  );
};


const App = ({
  basket,
  lang,
  shop,
  shopPage,
  user,
  loadLang,
  loadShop,
  loadUser
}) => {
  React.useEffect(() => {
    loadLang();
  }, [loadLang]);

  React.useEffect(() => {
    loadShop();
  }, [loadShop]);

  React.useEffect(() => {
    loadUser();
  }, [loadUser]);

  const { t } = useTranslation();

  const customFonts = shopPage?.data?.font === 'quiche'
    ? 'quiche-light-custom-font' : ''

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcYB7YUAAAAAL-gVDC4HSjK99pZqkoAu5DuF1Sy"
      language="nl"
      scriptProps={{
        async: true,
        appendTo: 'body',
      }}
    >
      <HelmetProvider context={helmetContext}>
        <ShopContext.Provider value={shop}>
          <LangContext.Provider value={lang}>
            <CookieConsent
              location="bottom"
              style={{ alignItems: 'center', background: "#2B373B" }}
              buttonStyle={{
                background: "#2B373B",
                color: "white",
                border: "white 1px solid"
              }}
              buttonText={t('accept')}
              expires={150}
            >
              <div dangerouslySetInnerHTML={{ __html: t('gdpr.text') }} />
            </CookieConsent>
            <NotificationContainer />
            <div className={`App ${customFonts}`}>
              { shop && <ShopMetaTags shop={shop} /> }
              <Header
                basket={basket}
                content={shopPage?.data?.header}
                csc={customFonts}
                loadLang={loadLang}
                shop={shop}
                user={user}
              />
              {renderRoutes()}
              <Footer csc={customFonts} footer={shopPage?.data?.footer} />
              <ScrollTop />
            </div>
          </LangContext.Provider>
        </ShopContext.Provider>
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  );
};

export default connect(
  (state) => ({
    basket: state.basket,
    lang: state.lang,
    shop: state.shop,
    shopPage: state.shopPage,
    user: state.user
  }),
  {
    // authUser,
    loadLang,
    loadShop,
    loadUser,
    // logoutUser,
  },
)(App);
