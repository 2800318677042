import axios from 'axios';
import Cookie from "js-cookie";

import { authUser as authUserAPI, getUser } from 'api/auth';
import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const SET_USER = 'SET_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_SIGNUP = 'USER_SIGNUP';

export const setUser = (data) => {
  return {
    type: SET_USER,
    data
  }
};

const processGetUser = (data, dispatch) => {
  if (data?.token) {
    Cookie.set('token', data?.token || null);
    axios.defaults.headers.common.Authorization = 'Token ' + data?.token;
  }
  dispatch(setUser(data));
};

export const authUser = (code) => async (dispatch) => {
  let result = null;
  try {
    result = await authUserAPI(code);
  } catch (error) {
    result = error.response;
  }
  if (result?.status === 200 && result?.data) {
    processGetUser(result.data, dispatch);
  } else {
    dispatch(setUser(null));
  }
  return result
};

export const loadUser = () => async (dispatch) => {
  let result = null;
  try {
    result = await getUser();
  } catch (error) {
    result = error.response;
  }
  if (result.status === 200 && result.data) {
    processGetUser(result.data, dispatch);
  } else {
    dispatch(setUser(null));
  }
};

export const login = (data) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: USER_SIGNIN,
    url: API_URLS.user.login
  });
};

export const logout = () => (dispatch, getState) => {
  Cookie.remove("token");
  localStorage.removeItem("persistantState");
  dispatch(setUser(null));
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params: null,
    state: getState(),
    type: USER_LOGOUT,
    url: API_URLS.user.logout
  });
};


export const password_reset = (data) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: PASSWORD_RESET,
    url: API_URLS.user.v1.password.forgot
  });
};

export const signup = (data) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: USER_SIGNUP,
    url: API_URLS.user.signup
  });
};