import { useEffect, useState } from 'react';

export const ScrollTop = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  if (scrollPosition < 150) {
    return (<></>);
  }
  return (
    <div
      className="scroll-top-arrow"
      onClick={() => scrollTop()}
      style={{ display: 'inline' }}
    >
      <i className="feather icon-feather-arrow-up"></i>
    </div>
  );
};