import React, { lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";

// import PrivateRoute from 'components/routers';

const Login = lazy(() => import('pages/Login'));
const SignUpSuccess = lazy(() => import('pages/signup/Success'));
const SignupActivationSuccess = lazy(() => import('pages/signup/activation/Success'));
const Logout = lazy(() => import('pages/Logout'));
const Home = lazy(() => import('pages/Home'));
const Shop = lazy(() => import('pages/Shop'));
const Basket = lazy(() => import('pages/Basket'));
const Checkout = lazy(() => import('pages/Checkout'));
const Product = lazy(() => import('pages/Product'));
const PasswordForgot = lazy(() => import('pages/signin/password/Forgot'));
const PasswordResetSuccess = lazy(() => import('pages/signin/password/ResetSuccess'));

export const URLS = {
  ROOT: '/',
  HOME: '/home',
  LOGIN: '/login',
  SIGNUP: {
    ACTIVATION: {
      SUCCESS: '/signup/activation/success'
    },
    SUCCESS: '/login/success'
  },
  LOGOUT: '/logout',
  SHOP: '/shop',
  BASKET: '/shop/basket',
  CHECKOUT: '/shop/checkout',
  CATEGORY_PRODUCT: 'shop/:categoryName/:productName-:productId',
  PRODUCT: 'shop/product/:productName-:productId',
  EXTERNAL: {
    MIJNBEDRIJFOOK: 'https://mijnbedrijfook.nl/',
    PRIVACY: '/privacy/',
    TERMS: '/disclaimer/',
    USER: {
      FORGOT_PASSWORD: '/profile/credentials/forgot/',
      ORDER: '/profile/orders/'
    }
  },
  USER: {
    PASSWORD: {
      FORGOT: '/password/forgot',
      RESET_SUCCESS: '/password/reset/success'
    }
  }
}

const renderRoutes = () => (
  <Suspense fallback={<p> Loading...</p>}>
    <Routes>
      <Route exact path={URLS.LOGIN} element={<Login />} is_lazy />
      <Route exact path={URLS.SIGNUP.SUCCESS} element={<SignUpSuccess />} is_lazy />
      <Route exact path={URLS.SIGNUP.ACTIVATION.SUCCESS} element={<SignupActivationSuccess />} is_lazy />
      <Route exact path={URLS.LOGOUT} element={<Logout />} is_lazy />
      <Route exact path={URLS.ROOT} element={<Home />} is_lazy />
      <Route exact path={URLS.HOME} element={<Home />} is_lazy />
      <Route exact path={URLS.SHOP} element={<Shop />} is_lazy />
      <Route exact path={URLS.BASKET} element={<Basket />} is_lazy />
      <Route exact path={URLS.CHECKOUT} element={<Checkout />} is_lazy />
      <Route exact path={URLS.CATEGORY_PRODUCT} element={<Product />} is_lazy />
      <Route exact path={URLS.PRODUCT} element={<Product />} is_lazy />
      <Route exact path={URLS.USER.PASSWORD.FORGOT} element={<PasswordForgot />} is_lazy />
      <Route exact path={URLS.USER.PASSWORD.RESET_SUCCESS} element={<PasswordResetSuccess />} is_lazy />
    </Routes>
  </Suspense>
);

export default renderRoutes;