import axios from 'axios';

import { API_URLS } from 'api/urls';

export const authUser = (code) => {
  return axios.post(API_URLS.user.auth, {code});
};

export const getUser = () => {
  return axios.get(API_URLS.user.me);
};