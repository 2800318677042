import axios from 'axios';
import Cookie from "js-cookie";

import { SET_LANG } from 'redux/actions';
import { SET_USER, USER_LOGOUT, USER_SIGNIN } from 'redux/actions/auth';
import {
  ADD_PRODUCT_TO_BASKET,
  REMOVE_PRODUCT_TO_BASKET,
  UPDATE_BASKET_PRODUCT,
  EMPTY_BASKET,
  SET_BASKET
} from 'redux/actions/basket';
import {
  SET_SHOP,
  SET_FILTERS,
  SET_SHOP_PAGE,
  SET_SHOP_PAGE_INSTA
} from 'redux/actions/shop';
import {
  FETCH_SHOP_PRODUCT_ONE,
  FETCH_SHOP_PRODUCT_ALL,
  FETCH_FEATURED_SHOP_PRODUCT,
  FILTER_SHOP_PRODUCT,
  RESET_FILTER_SHOP_PRODUCT
} from 'redux/actions/shopproduct';
import { SUCCESS_SUFFIX } from 'core/settings';

const basket = (state = null, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_BASKET + SUCCESS_SUFFIX:
    case REMOVE_PRODUCT_TO_BASKET + SUCCESS_SUFFIX:
    case UPDATE_BASKET_PRODUCT + SUCCESS_SUFFIX:
      return action.payload.data;
    case SET_BASKET:
      return action.data;
    case EMPTY_BASKET + SUCCESS_SUFFIX:
      return null;
    default:
      return state;
  }
};

const filters = (state = null, action) => {
  switch (action.type) {
    case SET_FILTERS + SUCCESS_SUFFIX:
      return action.payload.data;
    default:
      return state;
  }
};

const lang = (state = null, action) => {
  switch (action.type) {
    case SET_LANG:
      return action.data;
    default:
      return state;
  }
};

const product = (state = null, action) => {
  switch (action.type) {
    case FETCH_SHOP_PRODUCT_ONE + SUCCESS_SUFFIX:
      const current = action.payload.data.results;
      return {
        ...state,
        current: current?.length > 0 ? current[0] : null
      };
    case FETCH_SHOP_PRODUCT_ALL + SUCCESS_SUFFIX:
      const current_page = action?.payload?.data?.page;
      const results = current_page > 0
        ? [
            ...state.all.results,
            ...action.payload.data.results
          ]
        : action.payload.data.results;
      return {
        ...state,
        all: {
          ...action.payload.data,
          results
        },
        filters: {}
      };
    case FETCH_FEATURED_SHOP_PRODUCT + SUCCESS_SUFFIX:
      return {
        ...state,
        featured: action.payload.data
      };
    case FILTER_SHOP_PRODUCT + SUCCESS_SUFFIX:
      const _all = state?._all || state.all;
      return {
        ...state,
        _all,
        all: action.payload.data,
        filters: action.payload.config.params
      };
    case RESET_FILTER_SHOP_PRODUCT:
      return {
        ...state,
        _all: null,
        all: state._all,
        filters: {}
      };
    default:
      return state;
  }
};

const shop = (state = null, action) => {
  switch (action.type) {
    case SET_SHOP:
      return action.data;
    default:
      return state;
  }
};

const shopPage = (state = null, action) => {
  switch (action.type) {
    case SET_SHOP_PAGE + SUCCESS_SUFFIX:
      const res = action.payload.data;
      return res && res[0];
    case SET_SHOP_PAGE_INSTA + SUCCESS_SUFFIX:
      return {
        ...state,
        instagram: action.payload.data
      };
    default:
      return state;
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      const user = action.data;
      // TODO: move to middleware
      if (action.data?.token) {
        Cookie.set('token', action.data?.token || null);
        axios.defaults.headers.common.Authorization = 'Token ' + action.data?.token;
      }
      return {
        ...user,
        is_company_customer: Boolean(user?.company?.id || user?.customer_is_company)
      };
    case USER_LOGOUT + SUCCESS_SUFFIX:
      Cookie.remove('token');
      return null;
    case USER_SIGNIN + SUCCESS_SUFFIX:
      const token = action?.payload?.data?.token;
      // TODO: move to middleware
      if (token) {
        Cookie.set('token', token || null);
        axios.defaults.headers.common.Authorization = 'Token ' + token;
      }
      return action.payload.data;
    default:
      return state;
  }
};

export {
  basket,
  filters,
  lang,
  product,
  shop,
  shopPage,
  user
};