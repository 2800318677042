import en from 'core/lang/en.json';

export const SUCCESS_SUFFIX = '_SUCCESS';
export const ERROR_SUFFIX = '_FAIL';

export const PRODUCT_ATTRIBUTES = ['size', 'color'];

const DEFAULTS = {
  COMPANY_ID: 553,
  HOST: 'https://www.ookmijnbedrijf.nl',
  LANGUAGE: en
};

export const LOAD_STATUSES = {
  INIT: 0,
  LOADING: 11,
  LOADED: 111
}
export const PAYMENT_TYPES = {
  UNKNOWN: 0,
  ONDELIVERY: 1,
  INSTORE: 2,
  PAYPAL: 3,
  MOLLIE: 4,
  CARD: 5,
  TIKKIE: 6,
  INVOICE: 7
}

export const config = {
  auth_type: process.env.REACT_APP_AUTH_TYPE || null,
  currency: '€',
  company_id: DEFAULTS.COMPANY_ID,
  host: process.env?.REACT_APP_FORCE_HOST === "true"
    ? (process.env.REACT_APP_HOST || DEFAULTS.HOST)
    : window.location.origin,
  isSmallScreen: window.innerWidth < 768,
  same_origin: Boolean(process.env.REACT_APP_SAME_ORIGIN === "true"),
  shop: {
    currency: '€',
  },
  language: DEFAULTS.LANGUAGE,
  username: {
    length: {
      max: 150
    },
    pattern: '^[A-Za-z0-9.@+-]+$'
  },
  password: {
    length: {
      min: 6
    }
  }
};

export const getInitialState = () => {
  return {
    filters: [],
    lang: config.language,
    product: {
      results: [],
      featured: null
    },
    routing: { locationBeforeTransitions: null },
    shop: null,
    user: null
  };
};

export const initialState = getInitialState();