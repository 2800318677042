import {
  getBasket
} from 'api/basket';
import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';

export const CHECKOUT = 'CHECKOUT';
export const EMPTY_BASKET = 'EMPTY_BASKET';
export const SET_BASKET = 'SET_BASKET';
export const DELETE_BASKET_ITEM = 'DELETE_BASKET_ITEM';
export const SET_BASKET_ITEM = 'SET_BASKET_ITEM';
export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET';
export const REMOVE_PRODUCT_TO_BASKET = 'REMOVE_PRODUCT_TO_BASKET';
export const UPDATE_BASKET_PRODUCT = 'UPDATE_BASKET_PRODUCT';
export const MOLLIE = 'MOLLIE';

export const setBasket = (data) => {
  return {
    type: SET_BASKET,
    data
  }
};

export const setBasketProduct = (data) => {
  return {
    type: SET_BASKET_ITEM,
    data
  }
};

export const loadBasket = (url) => async (dispatch) => {
  const result = await getBasket(url);
  if (result.status === 200 && result.data) {
    dispatch(setBasket(result.data));
  }
};

export const add = (params, product, shop) => (dispatch, getState) => {
  return makeDispatch({
    data: {
      discount_amount: product?.discount_amount || null,
      discount_percent: product?.discount_percent || null,
    },
    dispatch,
    method: 'POST',
    params,
    state: getState(),
    type: ADD_PRODUCT_TO_BASKET,
    url: shop.basket_url + `${product.id}/add/`
  });
};

export const empty = (shop) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params: null,
    state: getState(),
    type: EMPTY_BASKET,
    url: shop.basket_url + 'empty/'
  });
};

export const remove = (params, product, shop) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: REMOVE_PRODUCT_TO_BASKET,
    url: shop.basket_url + `${product.id}/delete/`
  });
};

export const update = (params, product, shop) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'PATCH',
    params,
    state: getState(),
    type: UPDATE_BASKET_PRODUCT,
    url: shop.basket_url + `${product.id}/update/`
  });
};

export const checkout = (order, shop) => (dispatch, getState) => {
  return makeDispatch({
    data: order,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: CHECKOUT,
    url: shop.basket_url + 'checkout/'
  });
};

export const mollie = (id) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: MOLLIE,
    url: API_URLS.shop.order + `${id}/payment_mollie/`
  });
};