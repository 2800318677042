import { getLang } from 'api/settings';

export const SET_LANG = 'SET_LANG';

export const setLang = (data) => {
  return {
    type: SET_LANG,
    data
  }
};

export const loadLang = (lang) => async (dispatch) => {
  const result = await getLang(lang || 'nl');
  if (result.status === 200 && result.data) {
    dispatch(setLang(result.data));
  }
};