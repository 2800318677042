export const API_URLS = {
  pages: {
    shop: '/companies/api/companies/pages/'
  },
  shop: {
    order: '/webshop/api/order/',
    filters: 'api/v2/shop/filters',
    product: 'api/v2/shop/product',
    voucher: '/webshop/api/voucher/',
    current: '/webshop/api/webshop/detail/current/'
  },
  translation: 'api/v1/translation/shop.json',
  user: {
    auth: '/accounts/api/user/auth/',
    me: '/accounts/api/user/me/',
    login: '/profile/credentials/api/login/',
    logout: '/profile/credentials/logout/',
    // signup: '/profile/credentials/api/customer/signup/',
    signup: 'api/v2/customer/signup',
    v1: {
      password: {
        forgot: 'api/v1/auth/password/forgot/'
      },
      signup: '/profile/credentials/api/customer/signup/',
    }
  }
}