import axios from 'axios';

import { API_URLS } from 'api/urls';

export const getShop = () => {
  return axios.get(API_URLS.shop.current);
};

export const getShopProducts = ({ params }) => {
  return axios.get(API_URLS.shop.current, params);
};