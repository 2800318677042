export const CompanyLogo = ({logo}) => {
  return (
    <div className="col-auto col-sm-6 col-lg-2 mr-auto pl-lg-0">
      <a className="navbar-brand" href="/">
        <img
          src={logo}
          className="default-logo"
          alt="company-logo"
        />
      </a>
    </div>
  );
};