import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { LangContext } from 'context';
import { LOAD_STATUSES } from 'core/settings';
import * as BasketActions from 'redux/actions/basket';
import { getAvailableQTY, getAvailableQTYInBasket } from 'utils/shop';

import { Price } from './Price';

export const ProductGeneric = ({ children, ...props }) => {
  const { basket, load, setLoad, product, shop } = props;
  const [changed, setChanged] = React.useState(false);
  const quantity = props.quantity || 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const aqty_ = getAvailableQTY(
    product,
    props.isBasket ? null : basket
  );
  const [aqty, setAQty] = React.useState(0);
  const [qty, setQty] = React.useState(0);
  const url = `/shop/product/${product.name}-${product?.parent || product.id}`;
  React.useEffect(() => {
    setAQty(aqty_ > 0 ? (aqty_ - quantity) : 0);
    setQty(aqty_ > 0 ? quantity : 0);
  }, [quantity, aqty_]);

  const add = React.useCallback((props) => {
    if (props?.usePage) {
      navigate(url);
      return;
    }
    if (load === LOAD_STATUSES.LOADING) {
      return;
    }
    setLoad(LOAD_STATUSES.LOADING);
    dispatch(BasketActions.add({ qty }, product, shop)).then(() => {
      setAQty(prevQty => prevQty -= qty);
      setLoad(LOAD_STATUSES.LOADED);
    });
  }, [
    dispatch,
    qty,
    load,
    navigate,
    setLoad,
    product,
    shop,
    url
  ]);

  const remove = React.useCallback((value) => {
    if (load === LOAD_STATUSES.LOADING) {
      return;
    }
    const qtyMax = getAvailableQTYInBasket(product, basket);
    const qty_ = Math.min(value || qty, qtyMax);
    setLoad(LOAD_STATUSES.LOADING);
    dispatch(BasketActions.remove(
      { qty: qty_ },
      product,
      shop
    )).then(() => {
      setAQty(prevQty => prevQty -= qty_);
      setLoad(LOAD_STATUSES.LOADED);
    });
  }, [basket, dispatch, product, qty, load, setLoad, shop]);

  const handleQty = (e) => {
    const value = parseInt(e.target.value);

    if (!value || value < 0) {
      setQty(null);
      return;
    }

    setQty(Math.min(parseInt(value), aqty));
    setChanged(true);
  }

  const handleMinusButton = () => {
    console.log('-')
    setQty(prevQty => --prevQty);
    setAQty(prevQty => ++prevQty);
    setChanged(true);
  }

  const handlePlusButton = () => {
    console.log('+')
    setQty(prevQty => ++prevQty);
    setAQty(prevQty => --prevQty);
    setChanged(true);
  }

  const update = () => {
    if (load === LOAD_STATUSES.LOADING) {
      return;
    }
    setLoad(LOAD_STATUSES.LOADING);
    dispatch(BasketActions.update({ qty }, product, shop)).then(() => {
      // setAQty(prevQty => prevQty -= qty);
      setLoad(LOAD_STATUSES.LOADED);
    });
  };

  return children({
    ...props,
    add,
    aqty,
    aqty_,
    changed,
    remove,
    qty,
    dispatch,
    handleMinusButton,
    handlePlusButton,
    handleQty,
    url,
    update
  });
};

export const AddToCartInput = (props) => {
  const {
    aqty,
    aqty_,
    qty,
    lang
  } = props;
  return (
    <>
      <label className="screen-reader-text">{lang?.quantity}</label>
      <input
        className="qty-minus qty-btn"
        disabled={qty === 0}
        onClick={() => props.handleMinusButton()}
        type="button"
        value="-"
      />
      <input
        className="input-text qty-text"
        type="number"
        name="quantity"
        value={qty}
        step="1"
        min="0"
        max={aqty_}
        onChange={props.handleQty}
      />
      <input
        type="button"
        value="+"
        className="qty-plus qty-btn"
        disabled={aqty === 0}
        onClick={() => props.handlePlusButton()}
      />
    </>
  )
};

export const Product = ({ product, ...props }) => {
  const lang = React.useContext(LangContext);
  const { load } = props;

  const getCurrentChild = () => {
    if (props?.currentFilter) {
      const filters = {};
      if (props.currentFilter?.size) {
        filters.size = props.currentFilter?.size
      }
      if (props.currentFilter?.color) {
        filters.color = props.currentFilter?.color
      }
      const availableValues = [...new Set(
        product.children.filter(item => {
          const cond = Object.entries(filters).every(
            val => item.data[val[0]] === val[1]
          );
          return cond;
        })
      )];

      if (availableValues?.length > 0) {
        return availableValues[0];
      }
    }
    return product.children[0] || product;
  }

  const selectedProduct = product?.has_variants
    ? getCurrentChild()
    : product;

  if (!selectedProduct.category) {
    selectedProduct.category = product.category;
  }

  const productBox = props.marginRules
    ? props.marginRules
    : "product-box";
  const productImage = props.rounded
    ? "product-image border-radius-6px"
    : "product-image";
  const url = `/shop/${product.category.name}/${product.name}-${product.id}`;

  return (
    <div className={productBox}>
      <div className={productImage}>
        <Link to={url}>
          <LazyLoadImage
            alt={`default-product-${product.id}`}
            className="default-image"
            height={400}
            src={
              props?.currentFilter
                ? ( selectedProduct?.image || selectedProduct.get_thumb_image_uri )
                : ( product?.image || product.get_thumb_image_uri)
            }
            width={400}
          />
          {
            <LazyLoadImage
              alt={`hover-product-${product.id}`}
              className="hover-image"
              height={400}
              src={
                product.has_media
                  ? product.media[0].image || product.get_thumb_image_uri
                  : product.get_thumb_image_uri
              }
              width={400}
            />
          }
          {
            product?.new
            &&
            <span className="product-badge red">{lang.new}</span>
          }
          {
            product?.sale
            &&
            <span class="product-badge green">{lang.sale}</span>
          }
        </Link>
        <div className="product-overlay bg-gradient-extra-midium-gray-transparent"></div>
        <ProductGeneric product={selectedProduct} {...props} >
          {
            parentMethods =>
              <div className="product-hover-bottom text-center padding-35px-tb">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {
                        selectedProduct.available_qty_in_stock > 0
                          ? lang.basket.add
                          : 'Out of stock'
                      }
                    </Tooltip>
                  }
                >
                  <button
                    className="product-link-icon move-top-bottom"
                    disabled={load === LOAD_STATUSES.LOADING}
                    onClick={() => parentMethods.add({
                      usePage: Boolean(product?.has_variants)
                    })}
                    style={{border: 'none'}}
                  >
                    <i className="feather icon-feather-shopping-cart"></i>
                  </button>
                </OverlayTrigger>
                {
                /*
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Quick shop</Tooltip>}
                >
                  <a
                    className="product-link-icon move-top-bottom"
                    onClick={() => (console.log('quickshop'))}
                    style={{ textDecoration: 'none' }}
                  >
                    <i className="feather icon-feather-zoom-in"></i>
                  </a>
                </OverlayTrigger>
                */
                }
                {
                /*
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Add to wishlist</Tooltip>}
                >
                  <a
                    className="product-link-icon move-top-bottom"
                    onClick={() => (console.log('wishlist'))}
                    style={{ textDecoration: 'none' }}
                  >
                    <i className="feather icon-feather-heart"></i>
                  </a>
                </OverlayTrigger>
                */
                }
              </div>
          }
        </ProductGeneric>
      </div>
      <div className="product-footer text-center padding-25px-top xs-padding-10px-top">
        <Link
          to={url}
          className="text-extra-dark-gray text-black-hover font-weight-500 d-inline-block one-row-text"
        >
          {product.get_full_name_alt}
        </Link>
        <div className="product-price text-medium">
          <Price price={selectedProduct.showing_price} />
        </div>
      </div>
    </div>
  );
};