import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';

export const FETCH_SHOP_PRODUCT_ONE = 'FETCH_SHOP_PRODUCT_ONE';
export const FETCH_SHOP_PRODUCT_ALL = 'FETCH_SHOP_PRODUCT_ALL';
export const FETCH_FEATURED_SHOP_PRODUCT = 'FETCH_FEATURED_SHOP_PRODUCT';
export const FILTER_SHOP_PRODUCT = 'FILTER_SHOP_PRODUCT';
export const RESET_FILTER_SHOP_PRODUCT = 'RESET_FILTER_SHOP_PRODUCT';

export const fetchOne = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_SHOP_PRODUCT_ONE,
    url: url || API_URLS.shop.product
  });
};

export const fetchAll = ({url, params}) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_SHOP_PRODUCT_ALL,
    url: url || API_URLS.shop.product
  });
};

export const featured = (params) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_FEATURED_SHOP_PRODUCT,
    url: API_URLS.shop.product
  });
};

export const filter = (params) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FILTER_SHOP_PRODUCT,
    url: API_URLS.shop.product
  });
};

export const reset = () => (dispatch) => {
  return dispatch({
    type: RESET_FILTER_SHOP_PRODUCT
  });
};