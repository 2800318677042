import React, { useState } from 'react';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt, faUserTie } from '@fortawesome/pro-light-svg-icons';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';

// import englishFlag from 'assets/images/country-flag-16X16/usa.png';
// import nlFlag from 'assets/images/country-flag-16X16/Netherlands.png';

import { LangContext } from 'context';
import { CompanyLogo } from 'components/header/logo';
import { Price } from 'components/Price';
import { ProductGeneric } from 'components/Product';
import { URLS } from 'router';
import { LOAD_STATUSES } from 'core/settings';
import { logout } from 'redux/actions/auth';

const MenuItem = ({ item, classNames }) => {
  return (
    <li>
      <a
        href={item.url}
        style={{ padding: '9px 15px' }}
        className={classNames || ''}
      > {item.title}</a></li>
  );
};

const MenuItemExpanded = ({ csc, item }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };
  return (
    <li className="nav-item dropdown simple-dropdown dd-custom open show">
      <a
        href={item.url}
        className={`nav-link ${csc}`}
        style={{ padding: '9px 15px' }}
      >{item.title}</a>
      <FontAwesomeIcon
        aria-hidden="true"
        aria-expanded={menuOpen}
        className="dropdown-toggle-c cursor-pointer"
        icon={menuOpen ? faAngleUp : faAngleDown}
        onClick={() => toggleMenu()}
        style={{ right: '15px', height: '15px', width: '15px', top: '16.5px'}}
      />
      {
        menuOpen
        &&
        <ul className="dropdown-menu dd-custom show" role="menu">
          {
            (item?.children || []).map((child, index) => {
              return (
                <MenuItem
                  item={child}
                  key={index}
                  classNames="text-decoration-none"
                />
              );
            })
          }
        </ul>
      }
    </li>
  );
};


const Menu = ({ csc, menu }) => {
  return (
    <div
      className="collapse show navbar-collapse justify-content-center"
      id="navbarNav"
    >
      <ul className={`navbar-nav alt-font ${csc}`}>
        {
          /*
          <li className="nav-item">
            <a
              href={URLS.HOME}
              className="nav-link"
              style={{ padding: '9px 15px' }}
            >{lang.home.title}</a>
          </li>
          */
        }
        {
          menu.map((item, index) => {
            return  item?.expanded
              ? (<MenuItemExpanded csc={csc} item={item} key={index} />)
              : (
                  <MenuItem
                    classNames={`nav-link ${csc}`}
                    item={item}
                    key={index}
                  />
                );
          })
        }
      </ul>
    </div>
  );
};

const CartItem = (props) => {
  const { item, load, setMenuOpen, url, user } = props;
  const lang = React.useContext(LangContext);
  return (
    <li className={`cart-item align-items-center ${props.csc}`}>
      <button
        className={`alt-font close ${props.csc}`}
        disabled={load === LOAD_STATUSES.LOADING}
        onClick={() => props.remove()}
        type="button"
      >×</button>
      <div className="product-image">
        <Link
          to={url}
          onClick={() => setMenuOpen(false)}
        >
          <img
            alt={`cart-thumb-product-${item.product.id}`}
            className="cart-thumb"
            src={item.product.get_thumb_image_uri}
          />
        </Link>
      </div>
      <div className={`product-detail alt-font ${props.csc}`}>
        <Link
          to={url}
          onClick={() => setMenuOpen(false)}
        >
          {item.product.get_full_name_alt}
        </Link>
        <span className="item-ammount">
          {
            user?.is_company_customer === true
              ? <Price price={item.showing_price} />
              : <Price price={item.price} />
          }
        </span>
        <span className="item-ammount">{lang?.qty}: {item.quantity}</span>
      </div>
    </li>
  )
};

const Cart = ({ basket, csc, lang, shop, user }) => {
  const [load, setLoad] = React.useState(LOAD_STATUSES.INIT);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };
  const _clsNm = menuOpen
    ? 'header-cart-icon dropdown open'
    : 'header-cart-icon dropdown'
  return (
    <div className={_clsNm}>
      <button
        onClick={() => toggleMenu()}
        type="button"
        style={{
          background: 'transparent',
          border: 'none',
          padding: 0,
        }}
      >
        <i className="feather icon-feather-shopping-bag"></i>
        <span
          className={`cart-count alt-font bg-fast-blue text-white ${csc}`}
          style={{
            top: '-2px'
          }}
        >
          { basket.cart_count }
        </span>
      </button>
      <ul className="dropdown-menu cart-item-list">
        <li className="cart-item align-items-center">
          <button
            className="alt-font close"
            onClick={() => setMenuOpen(false)}
            type="button"
            style={{
              border: 'none',
              fontSize: '32px',
              lineHeight: '16px',
              right: 0
            }}
          >×</button>
        </li>
        {
          basket.items.map((item, index) => {
            return (
              <ProductGeneric
                key={index}
                basket={basket}
                load={load}
                setLoad={setLoad}
                product={item.product}
                shop={shop}
                quantity={item.quantity}
                isBasket={true}
              >
                {
                  parentMethods =>
                    <CartItem
                      csc={csc}
                      item={item}
                      setMenuOpen={setMenuOpen}
                      user={user}
                      {...parentMethods}
                    />
                }
              </ProductGeneric>
            );
          })
        }
        <li className={`cart-item cart-total ${csc}`}>
          <div className={`alt-font margin-15px-bottom ${csc}`}>
            <span className={`w-50 d-inline-block text-medium text-uppercase ${csc}`}>
              { lang.checkout.subtotal }:
            </span>
            <span className={`w-50 d-inline-block text-right text-medium font-weight-500 ${csc}`}>
              <Price price={basket.total} />
            </span>
          </div>
          <Link
            className={`btn btn-small btn-dark-gray ${csc}`}
            to={URLS.BASKET}
            onClick={() => setMenuOpen(false)}
          >
            {lang.basket.view}
          </Link>
          <Link
            className={`btn btn-small btn-dark-gray ${csc}`}
            to={URLS.CHECKOUT}
            onClick={() => setMenuOpen(false)}
          >
            {lang.checkout.view}
          </Link>
        </li>
      </ul>
    </div>
  );
};

/*
const LanguageForm = ({ loadLang }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const setLanguage = (lang) => {
    loadLang(lang);
    setMenuOpen(false);
  };
  return (
    <div
      className={
        menuOpen
          ? "header-language dropdown d-lg-inline-block open"
          : "header-language dropdown d-lg-inline-block"
      }
    >
      <button
        onClick={() => toggleMenu()}
        type="button"
        style={{ background: 'transparent', border: 'none' }}
      >
        <i className="feather icon-feather-globe"></i>
      </button>
      <ul className="dropdown-menu alt-font">
        <li>
          <button
            className="btn btn-link text-decoration-none"
            onClick={() => setLanguage('en')}
          >
            <span className="icon-country">
              <img src={englishFlag} alt="en-flag" />
            </span>
            English
          </button>
        </li>
        <li>
          <button
            className="btn btn-link text-decoration-none"
            onClick={() => setLanguage('nl')}
          >
            <span className="icon-country">
              <img src={nlFlag} alt="nl-flage" />
            </span>
            Netherlands
          </button>
        </li>
      </ul>
    </div>
  );
};

const SearchForm = () => {
  const [openForm, setOpenForm] = useState(false);
  const _clsNm = openForm
    ? 'header-search-icon search-form-wrapper active-form'
    : 'header-search-icon search-form-wrapper'
  return (
    <div className={_clsNm}>
      <button
        className="search-form-icon header-search-form"
        onClick={() => setOpenForm(true)}
        type="button"
        style={{background: 'transparent', border: 'none'}}
      >
        <i className="feather icon-feather-search"></i>
      </button>
      <div className="form-wrapper">
        <div
          style={{ height: '100%', width: '100%' }}
          onClick={() => setOpenForm(false)}
        ></div>
        <button
          title="Close"
          type="button"
          className="search-close alt-font"
          onClick={() => setOpenForm(false)}
          style={{ zIndex: 3 }}
        >×</button>
        <form
          id="search-form"
          role="search"
          method="get"
          className="search-form text-left"
          action="."
          style={{zIndex: 2}}
        >
          <div className="search-form-box">
            <span
              className="search-label alt-font text-small text-uppercase text-medium-gray"
            >What are you looking for?</span>
            <input
              className="search-input alt-font"
              id="search-form-input5e219ef164995"
              placeholder="Enter your keywords..."
              name="s"
              defaultValue=""
              type="text"
              autoComplete="off"
            />
            <button type="submit" className="search-button">
              <i className="feather icon-feather-search" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
*/

const CPPage = ({ csc, page }) => {
  return (
    <li>
      {
        page?.is_abs_url
          ? <a className={csc} href={'/' + page?.url}>{page?.label}</a>
          : <a className={csc} href={'/profile/' + page?.url}>{page?.label}</a>
      }
    </li>
  )
};

const CPPanel = ({ csc, index, panel }) => {
  const pages = (panel?.get_permitted || []).map((page, pindex) => {
    return (<CPPage csc={csc} page={page} key={pindex} />);
  });
  return (
    <Accordion.Item eventKey={index} as="li" className={csc}>
      <Accordion.Header style={{ lineHeight: '1rem' }}>
        {panel.label}
      </Accordion.Header>
      <Accordion.Collapse eventKey={index}>
        <ul style={{ marginLeft: '2rem' }}>
          {pages}
        </ul>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

const UserMenu = ({ csc, lang, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      navigate(URLS.ROOT);
    });
  };

  return (
    <div
      className={
        menuOpen
          ? "header-user dropdown d-lg-inline-block open"
          : "header-user dropdown d-lg-inline-block"
      }
    >
      <button
        onClick={() => toggleMenu()}
        type="button"
        style={{ background: 'transparent', border: 'none' }}
      >
        <FontAwesomeIcon icon={faUserTie} />
      </button>
      <ul
        className="dropdown-menu alt-font"
        style={{ minWidth: '290px' }}
      >
        <Accordion>
          {
            (user?.cpanel_panels?.items || []).map((panel, index) => {
              return (
                <CPPanel
                  csc={csc}
                  index={index}
                  key={index}
                  panel={panel} />
              );
            })
          }
        </Accordion>
        <li style={{padding: '15px'}}>
          <div
            onClick={handleLogout}
            className={`cursor-pointer ${csc}`}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              fixedWidth
              className="fa-sm mr-2 text-gray-400"
            />
            {lang.logout}
          </div>
        </li>
      </ul>
    </div>
  );
};

export const Header = ({ basket, content, csc, shop, user }) => {
  const lang = React.useContext(LangContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };
  return (
    <header className={menuOpen ? 'navbar-collapse-show' : ''}>
      <nav className="top-space navbar navbar-light header-light fixed-top header-reverse-scroll bg-white navbar-boxed">
        <div className="container-fluid nav-header-container">
          <CompanyLogo logo={content?.middle?.media?.image}/>
          <div className="col-auto text-right pr-0 font-size-0">
            {/*<SearchForm />*/}
            {/*<LanguageForm loadLang={loadLang} />*/}
            {
              basket
              &&
              <Cart
                basket={basket}
                csc={csc}
                lang={lang}
                shop={shop}
                user={user}
              />
            }
            {
              user?.id
                ? <UserMenu csc={csc} lang={lang} user={user} />
                : <div
                    className="header-user dropdown d-lg-inline-block"
                    style={{marginRight: '15px'}}
                  >
                    <Link to={URLS.LOGIN} >
                      <FontAwesomeIcon
                        icon={faSignIn}
                        className="fa-bold"
                        style={{color: 'black'}}
                      />
                    </Link>
                  </div>
            }
            <button
              className="navbar-toggler float-right"
              onClick={() => toggleMenu()}
              type="button"
              aria-controls="navbarNav"
              aria-label="Toggle navigation"
              aria-expanded={menuOpen}
            >
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </button>
            {
              menuOpen
              &&
              <Menu csc={csc} lang={lang} menu={content?.navbar?.menu || []} />
            }
          </div>
        </div>
      </nav>
    </header>
  )
};