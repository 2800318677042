import { getShop } from 'api/shop';
import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';
import { loadBasket } from './basket';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_SHOP = 'SET_SHOP';
export const SET_SHOP_PAGE = 'SET_SHOP_PAGE';
export const SET_SHOP_PAGE_INSTA = 'SET_SHOP_PAGE_INSTA';
export const SET_SHOP_PRODUCTS = 'SET_SHOP_PRODUCTS';

export const setFilters = (params) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: SET_FILTERS,
    url: API_URLS.shop.filters
  });
};

export const setShop = (data) => {
  return {
    type: SET_SHOP,
    data
  }
};

export const setShopProduct = (data) => {
  return {
    type: SET_SHOP_PRODUCTS,
    data
  }
};

export const loadShopPage = (shop) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params: {
      is_active: true,
      is_shop: true,
      company: shop.company
    },
    state: getState(),
    type: SET_SHOP_PAGE,
    url: API_URLS.pages.shop
  });
};

export const pageInstagram = (Id) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params: null,
    state: getState(),
    type: SET_SHOP_PAGE_INSTA,
    url: API_URLS.pages.shop + `${Id}/instagram/`
  });
};

export const loadShop = () => async (dispatch) => {
  const result = await getShop();
  if (result.status === 200 && result.data) {
    dispatch(setShop(result.data));
    dispatch(loadBasket(result.data.basket_url));
    dispatch(loadShopPage(result.data));
  } else {
    dispatch(setShop(null));
  }
};