export const getBasketProducts = (basket) => {
  return basket?.items.map((el) => el.product);
};

export const getAvailableQTYInBasket = (productId, basket) => {
  const bp = (basket?.items || []).find((el) => el.product_pk === productId);
  const basket_qty = bp && bp.quantity;
  return basket_qty || 0;
};

export const getAvailableQTYInStock = (product) => {
  if (product.available_qty_in_stock === null) {
    product.available_qty_in_stock = Number.POSITIVE_INFINITY;
    return product.available_qty_in_stock;
  }
  return product.available_qty_in_stock;
};

export const getAvailableQTY = (product, basket) => {
  const aqty = getAvailableQTYInStock(product);
  if (basket && basket?.items) {
    const basket_qty = getAvailableQTYInBasket(product.id, basket);
    return aqty - basket_qty;
  }
  return aqty;
};