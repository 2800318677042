import React from 'react';

import dLogo2x from 'assets/images/logo-white@2x.png';

import { LangContext } from 'context';
import { URLS } from 'router';

export const FooterLogo = ({ logo }) => {
  return (
    <a
      className="footer-logo margin-25px-bottom margin-5px-top d-inline-block"
      href={URLS.HOME}
    >
      <img
        alt="footer-logo"
        src={logo || dLogo2x}
      />
    </a>
  );
};

const Link = ({item}) => {
  return (
    <li>
      <a
        className="text-decoration-none"
        href={item.url}
      >
        {item.title}
      </a>
    </li>
  );
};

const LinkColumn = ({csc, title, links}) => {
  const htmlCLS = csc
    ? `font-weight-500 d-block text-white margin-15px-bottom xs-margin-10px-bottom ${csc}`
    : "alt-font font-weight-500 d-block text-white margin-15px-bottom xs-margin-10px-bottom"
  return (
    <>
      {
        links?.length > 0
        &&
        <div className="col-12 col-lg offset-xl-1 col-md-3 col-sm-4 order-1 order-lg-0 md-margin-40px-bottom xs-margin-25px-bottom">
            <span className={htmlCLS}>
            {title}
          </span>
          <ul>
            {
              (links || []).map((item, index) => {
                return (
                  <Link key={index} item={item} />
                )
              })
            }
          </ul>
        </div>
      }
    </>
  )
};

const Column1 = ({ csc, footer }) => {
  const htmlCLS = csc
    ? `font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom ${csc}`
    : "alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom"
  return (
    <div className="col-12 col-lg-3 col-sm-6 order-5 order-lg-0 text-md-center text-lg-left last-paragraph-no-margin">
      {footer?.media?.image && <FooterLogo logo={footer.media.image} />}
      <span className={htmlCLS}>
        {footer?.header?.text || ''}
      </span>
      <p>{footer?.content?.text || ''}</p>
    </div>
  );
};

const Column2 = ({ csc, footer }) => {
  return (
    <LinkColumn csc={csc} title={footer?.links_title || ''} links={footer?.links || []} />
  );
};

const Column3 = ({ csc, footer }) => {
  return (
    <LinkColumn csc={csc} title={footer?.links2_title || ''} links={footer?.links2 || []} />
  );
};

const Column4 = ({ csc, footer }) => {
  return (
    <LinkColumn csc={csc} title={footer?.links3_title || ''} links={footer?.links3 || []} />
  );
};

const Column5 = ({ csc, footer }) => {
  return (
    <LinkColumn csc={csc} title={footer?.links4_title || ''} links={footer?.links4 || []} />
  );
};

export const Footer = ({ csc, footer }) => {
  const lang = React.useContext(LangContext);
  const year = new Date().getFullYear();

  return (
    <footer className="footer-fashion-shop footer-dark bg-extra-dark-gray position-relative overlap-gap-section-bottom sm-padding-50px-top">
      <div className="footer-top padding-five-bottom padding-five-top">
        <div className="container">
          <div className="row justify-content-center">
            <Column1 csc={csc} footer={footer} />
            <Column2 csc={csc} footer={footer} />
            <Column3 csc={csc} footer={footer} />
            <Column4 csc={csc} footer={footer} />
            <Column5 csc={csc} footer={footer} />
          </div>
        </div>
      </div>
      <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 text-center text-md-left sm-margin-20px-bottom"></div>
            <div className="col-12 col-md-6 text-center last-paragraph-no-margin sm-margin-20px-bottom">
              <p>
                &copy; {lang.copyright} {year}
                &nbsp;
                <a
                  className="text-decoration-line-bottom text-white text-decoration-none"
                  href={URLS.EXTERNAL.MIJNBEDRIJFOOK}
                >
                  Mijnbedrijfook
                </a>
              </p>
            </div>
            <div className="col-12 col-md-3 text-center text-md-right">
              <div className="social-icon-style-12"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};