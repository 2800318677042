import axios from 'axios';
import Cookie from "js-cookie";

import { config } from 'core/settings';
import { API_URLS } from './urls';

export const getToken = () => {
  const _token = Cookie.get("token");

  if (!_token) {
    return;
  }

  return config.auth_type === 'token'
    ? 'Token ' + (_token || process.env.REACT_APP_TEST_TOKEN)
    : (_token && _token !== 'null') ? 'Token ' + _token : null;
};

export const getLang = (lang) => {
  return axios.get(API_URLS.translation, {
    params: {
      lang
    }
  });
};